import React, { useState } from 'react';
import * as AuthApi from "../../apis/auth.api";
import { NotificationBadge } from "../../components/Notifications/Notification";
import {FORGOT_MSG} from "../../constants/index";


import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [notificationBadge, setNotificationBadge] = useState({
    showNotification: false,
    isSuccess: null,
    message: "",
  });

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await AuthApi.forgotPassword({ email });
      console.log('handleforget response', response);
      
      if(response.status) {
          setNotificationBadge({
              showNotification: true,
              isSuccess: true,
              message: FORGOT_MSG,
          });
          
          // Redirect after 4 seconds
          setTimeout(() => {
              setNotificationBadge({ showNotification: false });
              window.location.href = 'auth/login'; // Change this to your login route
          }, 4000);

      } else {
          setNotificationBadge({
              showNotification: true,
              isSuccess: false,
              message: response?.message
          });
      }

    } catch (error) {
      setMessage(error.response?.data.message || 'An error occurred');
    } finally {
      setLoader(false);
    }
};


  return (
    <>
            {notificationBadge?.showNotification && <NotificationBadge notificationBadge={notificationBadge} setNotificationBadge={setNotificationBadge} />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Forgot Password</small>
            </div>
            <Form role="form" onSubmit={handleForgotPassword}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" style={{ backgroundColor: 'rgb(13,164,239)', border: 'none' }} type="submit" disabled={loader}>
                  {loader ? 'Sending...' : 'Send Reset Email'}
                </Button>
              </div>
            </Form>
            {message && (
              <div className="text-center text-muted mt-4">
                <small>{message}</small>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
