
export function mergeAiResponses(aiResponse, aiBiomarkerResponse) {
    try {
        // Create a map to quickly lookup aiBiomarkerResponse by name
        const biomarkerMap = new Map();
        aiBiomarkerResponse?.biomarkers?.forEach(biomarker => {
            biomarkerMap.set(biomarker?.name, biomarker);
        });

        // Merge aiResponse with aiBiomarkerResponse
        const mergedResponse = aiResponse.map(response => {
            const biomarkerDetails = biomarkerMap.get(response.name) || {};
            return {
                ...response,
                ...biomarkerDetails,
                cause_of_biomarker: biomarkerDetails.cause_of_biomarker || null,
                influenced_by: biomarkerDetails.influenced_by || [],
                summary: biomarkerDetails.summary || ''
            };
        });

        return mergedResponse;
    } catch (error) {
        return aiResponse;
    }
}