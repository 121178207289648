// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav#sidenav-main {
    background-color: #172b4d !important;
}

/* nav#sidenav-main {
    background-color: rgb(23,43,77) !important;
} */

.nav-link-custom {
    color: gray;
    /* Default color */
    display: flex;
    align-items: center;
}

.nav-link-custom i {
    margin-right: 8px;
}

.nav-link-custom.active {
    color: white;
    /* Color when active */
}

.active-sidebar {
    background-color: #8898aa !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;;GAEG;;AAEH;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["nav#sidenav-main {\n    background-color: #172b4d !important;\n}\n\n/* nav#sidenav-main {\n    background-color: rgb(23,43,77) !important;\n} */\n\n.nav-link-custom {\n    color: gray;\n    /* Default color */\n    display: flex;\n    align-items: center;\n}\n\n.nav-link-custom i {\n    margin-right: 8px;\n}\n\n.nav-link-custom.active {\n    color: white;\n    /* Color when active */\n}\n\n.active-sidebar {\n    background-color: #8898aa !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
