import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as AuthApi from "../../apis/auth.api";
import { useLocation } from 'react-router-dom';
import { NotificationBadge } from "../../components/Notifications/Notification";
import {RESET_MSG} from "../../constants/index"


import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

const ResetPassword = () => {
  const location = useLocation();
  const [notificationBadge, setNotificationBadge] = useState({
    showNotification: false,
    isSuccess: null,
    message: "",
  });

  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get('t'));
  }, [location.search]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await AuthApi.resetPassword({ token, password });
      console.log('resetPassword response', response);
      console.log(response);
      if(response.status){

        setNotificationBadge({
            showNotification: true,
            isSuccess: true,
            message: RESET_MSG,
        });
        setLoader(false);
          // Redirect after 4 seconds
          setTimeout(() => {
            setNotificationBadge({ showNotification: false });
            window.location.href = 'auth/login'; // Change this to your login route
        }, 4000);

      }else {
          setNotificationBadge({
              showNotification: true,
              isSuccess: false,
              message: response?.message
          });
      }

     
    } catch (error) {
      setMessage(error.response?.data.message || 'An error occurred');
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {notificationBadge?.showNotification && <NotificationBadge notificationBadge={notificationBadge} setNotificationBadge={setNotificationBadge} />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Reset Password</small>
            </div>
            <Form role="form" onSubmit={handleResetPassword}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your new password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" style={{ backgroundColor: 'rgb(13,164,239)', border: 'none' }} type="submit" disabled={loader}>
                  {loader ? 'Resetting...' : 'Reset Password'}
                </Button>
              </div>
            </Form>
            {message && (
              <div className="text-center text-muted mt-4">
                <small>{message}</small>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;
