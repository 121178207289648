// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1huaqfz-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
    color: rgb(0 0 0) !important;
}

.css-1sh6wjw-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0, 0, 0) !important;
    cursor: not-allowed !important;
}`, "",{"version":3,"sources":["webpack://./src/views/SampleReports/index.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,gDAAgD;IAChD,8BAA8B;AAClC","sourcesContent":[".css-1huaqfz-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {\n    color: rgb(0 0 0) !important;\n}\n\n.css-1sh6wjw-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {\n    -webkit-text-fill-color: rgb(0, 0, 0) !important;\n    cursor: not-allowed !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
